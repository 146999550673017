<template>
  <nav
    class="text-sm font-medium text-center text-gray-500 border-b dark:text-gray-400 dark:border-gray-700 mb-10"
  >
    <ul class="flex flex-wrap">
      <li v-for="tab in tabs" :key="tab.name" class="mr-2 w-full h6:w-fit">
        <router-link
          :to="tab.to"
          class="inline-block k1:p-4 p-1 border-b-4 text-start rounded-t-lg w-full"
          :class="{
            'active text-blue-600 dark:text-blue-500 dark:border-blue-500 border-blue-600':
              $route.name === tab.routeName,
            'hover:text-gray-600 border-gray-200 hover:border-gray-300 dark:hover:text-gray-300':
              $route.name !== tab.routeName,
          }"
        >
          {{ tab.name }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      required: true,
    },
  },
};
</script>
